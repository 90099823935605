import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'
import { stripUnit } from 'polished'

import { navHeightMobile, navHeight } from 'styles/variables'
import { media } from 'styles/media'
import { primaryColor, white } from 'styles/colors'
import { cover } from 'styles/helpers'
import { fontSizes, fontFamilies } from 'styles/typography'
import * as spacing from 'styles/spacing'

import Wrapper from 'components/Wrapper'
import Nav from 'components/Nav'
import Heading from 'components/Heading'

const Container = styled.div`
  position: relative;
  background-color: ${primaryColor};
  padding-top: ${navHeightMobile};
  overflow: hidden;

  ${media.medium`
    padding-top: ${navHeight};
  `};
`

const Inner = styled.div`
  position: relative;
  z-index: 10;
  padding-top: 4rem;
  padding-bottom: 8rem;

  ${media.small`
    padding-top: 6rem;
    padding-bottom: 13rem;
  `};

  ${media.medium`
    padding-top: 9rem;
    padding-bottom: 15rem;
  `};

  ${media.large`
    padding-top: 10rem;
    padding-bottom: 18rem;
  `};

  ${media.xLarge`
    padding-top: 12rem;
    padding-bottom: 20rem;
  `};
`

const Title = styled(Heading)`
  color: ${white};

  ${media.small`
    max-width: 690px;
  `};

  ${media.medium`
    max-width: 750px;
    font-size: 44px;
  `};

  ${media.large`
    max-width: 700px;
    font-size: 48px;
  `};

  ${media.xLarge`
    max-width: 900px;
    font-size: 56px;
  `};
`

const Eyebrow = styled.span`
  display: block;
  color: ${white};
  font-size: ${fontSizes.small};
  font-family: ${fontFamilies.heading};
  text-transform: uppercase;
  margin-bottom: ${spacing.small};

  ${media.small`
    margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
  `};

  ${media.medium`
    margin-bottom: ${spacing.medium};
  `};

  ${media.large`
    margin-bottom: ${stripUnit(spacing.medium) * 1.25 + 'px'};
  `};
`

const BackgroundWrap = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 50%;
`

const Background = styled.img`
  ${cover('absolute')};
  object-fit: cover;
  object-position: top;
`

const Hero = () => (
  <Container id="top">
    <Nav />
    <Inner>
      <Wrapper>
        <Eyebrow
          as={ScrollAnimation}
          animateIn="fadeInUp"
          offset={0}
          delay={400}
          animateOnce
        >
          Science Finance Venture Capital
        </Eyebrow>
        <ScrollAnimation
          animateIn="fadeInUp"
          offset={0}
          delay={600}
          animateOnce
        >
          <Title size={1}>
            We’re an early stage generalist firm with emphasis on fintech,
            artificial intelligence, and science.
          </Title>
        </ScrollAnimation>
      </Wrapper>
    </Inner>
    <BackgroundWrap
      as={ScrollAnimation}
      animateIn="fadeInUp"
      offset={0}
      delay={200}
      animateOnce
    >
      <Background
        src={require(`assets/images/header-bg.png`)}
        alt=""
        role="presentation"
      />
    </BackgroundWrap>
  </Container>
)

export default Hero
