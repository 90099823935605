import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'

import Wrapper from 'components/Wrapper'
import Section, { SectionBlock } from 'components/Section'
import Heading from 'components/Heading'
import FocusArea from 'components/FocusArea'

import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  position: static;
`

const Intro = styled.div`
  position: static;
  max-width: 900px;

  ${media.xLarge`
    max-width: 1040px;
  `};
`

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${spacing.large};

  ${media.small`
    grid-gap: ${spacing.xLarge};
    grid-template-columns: repeat(2, 1fr);
  `};

  ${media.medium`
    grid-gap: ${spacing.xxLarge};
  `};

  ${media.large`
    grid-gap: ${spacing.xxxLarge};
  `};

  ${media.xLarge`
    grid-gap: 64px;
  `};
`

const About = () => (
  <Container id="about">
    <Section flushBottom>
      <Wrapper>
        <SectionBlock>
          <Intro
            as={ScrollAnimation}
            animateIn="fadeInUp"
            offset={0}
            animateOnce
          >
            <Heading size={2}>
              SciFi is Max and Nellie Levchin’s venture fund that partners with
              exceptional founders solving hard problems that require intense
              curiosity, intellectual depth, and often, science.{' '}
            </Heading>
          </Intro>
        </SectionBlock>

        <SectionBlock>
          <ScrollAnimation
            animateIn="fadeInUp"
            offset={0}
            delay={200}
            animateOnce
          >
            <Heading size={5}>Focus Areas</Heading>
          </ScrollAnimation>
        </SectionBlock>

        <SectionBlock>
          <ScrollAnimation
            animateIn="fadeInUp"
            offset={0}
            delay={400}
            animateOnce
          >
            <List>
              <FocusArea
                icon="money"
                name="Fintech"
                description="We believe that technology will enable the next generation of financial products that combine access, efficiency, and transparency for businesses and consumers. Our fintech investments include credit, infrastructure, insurance, payments, and real estate."
              />
              <FocusArea
                icon="shop"
                name="Artificial Intelligence"
                description="Artificial intelligence (AI) will be integral for the next generation of breakthrough companies. From AI infrastructure layer innovations to app-layer startups solving real world problems, we’re looking to partner with entrepreneurs who are pushing the frontier of what’s possible in an AI-powered future. "
              />
              <FocusArea
                icon="science"
                name="Science"
                description="Our mission is to bring science fiction to reality. We actively invest in the commercialization of breakthrough technologies such as machine learning, CRISPR, robotics, and quantum computing. "
              />
              <FocusArea
                icon="space"
                name="Everything Else"
                description="Breakout companies can be built in any category, so we are excited to back inspiring founders across sectors. We seek out exceptional founders in hard, regulated, or technical markets such as enterprise software, healthcare, and security. "
              />
            </List>
          </ScrollAnimation>
        </SectionBlock>
      </Wrapper>
    </Section>
  </Container>
)

export default About
