import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'

import Wrapper from 'components/Wrapper'
import Section, { SectionBlock } from 'components/Section'
import LogoWall from 'components/LogoWall'
import Heading from 'components/Heading'

const Container = styled.div`
  position: static;
`

const Portfolio = () => (
  <Container id="portfolio">
    <Section flushBottom>
      <Wrapper>
        <SectionBlock>
          <ScrollAnimation animateIn="fadeInUp" offset={0} animateOnce>
            <Heading size={5}>Select Portfolio Companies</Heading>
          </ScrollAnimation>
        </SectionBlock>

        <SectionBlock>
          <ScrollAnimation
            animateIn="fadeInUp"
            offset={0}
            delay={200}
            animateOnce
          >
            <LogoWall
              logos={[
                {
                  name: 'aven',
                  link: 'https://aven.com/',
                },
                {
                  name: 'bland',
                  link: 'https://bland.ai/',
                },
                {
                  name: 'brex',
                  link: 'https://brex.com/',
                },
                {
                  name: 'capchase',
                  link: 'https://www.capchase.com/',
                },
                {
                  name: 'clipboard',
                  link: 'https://clipboardhealth.com/',
                },
                {
                  name: 'cloudtrucks',
                  link: 'https://cloudtrucks.com/',
                },
                {
                  name: 'coast',
                  link: 'https://www.coastpay.com/',
                },
                {
                  name: 'empora',
                  link: 'https://www.emporatitle.com/',
                },
                {
                  name: 'nestmed',
                  link: 'https://nestmed.com/',
                },
                {
                  name: 'maihem',
                  link: 'https://maihem.ai/',
                },
                {
                  name: 'playground-ai',
                  link: 'https://playground.com/',
                },
                {
                  name: 'pomelo',
                  link: 'https://pomelo.la/',
                },
                {
                  name: 'psiquantum',
                  link: 'https://psiquantum.com/',
                },
                {
                  name: 'settle',
                  link: 'https://settle.co/',
                },       
                {
                  name: 'supergood',
                  link: 'https://supergood.ai/',
                },
                {
                  name: 'virta',
                  link: 'https://www.virtahealth.com/',
                },
              ]}
            />
          </ScrollAnimation>
        </SectionBlock>
      </Wrapper>
    </Section>
  </Container>
)

export default Portfolio
